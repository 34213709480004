<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="380"
                  :options="optionsBarRejectedCount"
                  :series="seriesBarRejectedCount"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <pagination
              class="pagination-no-border"
              v-model="pagination.error_code.currentPage"
              :per-page="pagination.error_code.perPage"
              :total="total.error_code"
              @input="setPageErrorCode"
            />
            <h4 class="title">Motivos de recusa de pagamento AIH</h4>
            <p class="category">
              Rejeitados de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
            <md-button
              v-if="location_type"
              class="md-raised md-info md-m-0 md-block"
              :to="
                `/mosaico/sih/rejected/details/${source}/${type}/${id}/${location_type}/${location_id}`
              "
            >
              Detalhes
            </md-button>
            <md-button
              v-else
              class="md-raised md-info md-m-0 md-block"
              :to="`/mosaico/sih/rejected/details/${source}/${type}/${id}/`"
            >
              Detalhes
            </md-button>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="380"
                  :options="optionsBarRejectedValues"
                  :series="seriesBarRejectedValues"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <pagination
              class="pagination-no-border"
              v-model="pagination.error_value.currentPage"
              :per-page="pagination.error_value.perPage"
              :total="total.error_value"
              @input="setPageErrorValue"
            />
            <h4 class="title">Motivos de recusa de pagamento AIH</h4>
            <p class="category">
              Rejeitados de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
            <md-button
              v-if="location_type"
              class="md-raised md-info md-m-0 md-block"
              :to="
                `/mosaico/sih/rejected/details/${source}/${type}/${id}/${location_type}/${location_id}`
              "
            >
              Detalhes
            </md-button>
            <md-button
              v-else
              class="md-raised md-info md-m-0 md-block"
              :to="`/mosaico/sih/rejected/details/${source}/${type}/${id}/`"
            >
              Detalhes
            </md-button>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="380"
                  :options="optionsBarCid"
                  :series="seriesBarCid"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <pagination
              class="pagination-no-border"
              v-model="pagination.cid.currentPage"
              :per-page="pagination.cid.perPage"
              :total="total.cid"
              @input="setPageCid"
            />
            <h4 class="title">
              Classificação de AIH por diagnostico principal
            </h4>
            <p class="category">
              Principais CIDs de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-aih-value">
                <apexchart
                  type="line"
                  height="380"
                  :options="optionsLinesCid"
                  :series="seriesLinesCid"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Incidencia de CID por mês de competência</h4>
            <p class="category">
              Cid de maior ocorrência
              <span class="text-success">
                {{ cids[0] }} em {{ dataSet.year }} {{ region.name }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="380"
                  :options="optionsBarCidDnc"
                  :series="seriesBarCidDnc"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <pagination
              class="pagination-no-border"
              v-model="pagination.cidDnc.currentPage"
              :per-page="pagination.cidDnc.perPage"
              :total="total.cidDnc"
              @input="setPageCidDnc"
            />
            <h4 class="title">
              Classificação de AIH por diagnostico principal
            </h4>
            <p class="category">
              Principais CIDs de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-aih-value">
                <apexchart
                  type="line"
                  height="380"
                  :options="optionsLinesCidDnc"
                  :series="seriesLinesCidDnc"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Incidencia de CID por mês de competência</h4>
            <p class="category">
              Cid de maior ocorrência
              <span class="text-success">
                {{ cidsDnc[0] }} em {{ dataSet.year }} {{ region.name }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar-sex">
                <apexchart
                  type="bar"
                  height="380"
                  :options="chartOptionsSex"
                  :series="seriesSex"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">
              A pirâmide etária e distribuição por sexo de atendimento AIH
            </h4>
            <p class="category">
              Cid de maior ocorrência
              <span class="text-success">
                {{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { Pagination } from "@/components";

export default {
  components: {
    apexchart: VueApexCharts,
    Pagination
  },
  data() {
    return {
      region: { name: "" },
      source: this.$route.params.source,
      type: this.$route.params.type,
      id: this.$route.params.id,
      location_type: this.$route.params.location_type
        ? this.$route.params.location_type
        : null,
      location_id: this.$route.params.location_id
        ? this.$route.params.location_id
        : null,
      dataSet: { year: null, alias: null },
      filter_cids: this.$store.state.aih.filter_cids,
      filter_chapters_cids: this.$store.state.aih.filter_chapters_cids,
      filter_groups_cids: this.$store.state.aih.filter_groups_cids,
      seriesBarRejectedCount: [],
      optionsBarRejectedCount: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "10px"
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação de AIH Rejeitadas",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Ordenado pela incidência do código de erro",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      seriesBarRejectedValues: [],
      optionsBarRejectedValues: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "10px"
          },
          formatter: function(val, opt) {
            return (
              opt.w.globals.labels[opt.dataPointIndex] +
              ":  " +
              val.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })
            );
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação de AIH Rejeitadas",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Ordenado pelo valor retido",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                });
              }
            },
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      cids: [],
      seriesLinesCid: [],
      optionsLinesCid: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Distribuição CIDs por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        tooltip: {
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-BR");
              }
            }
          }
        }
      },
      seriesBarCid: [],
      optionsBarCid: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "10px"
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação de AIH por CID",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Ordenado pela incidência de CID",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      cidsDnc: [],
      seriesLinesCidDnc: [],
      optionsLinesCidDnc: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Distribuição CIDs de DNC e Observadas por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        tooltip: {
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-BR");
              }
            }
          }
        }
      },
      seriesBarCidDnc: [],
      optionsBarCidDnc: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "10px"
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação de AIH por CIDs de DNC e Observadas",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Ordenado pela incidência de CID",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      seriesSex: [],
      chartOptionsSex: {
        chart: {
          type: "bar",
          height: 440,
          stacked: true
        },
        colors: ["#008FFB", "#FF4560"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },

        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: -10,
          max: 10,
          title: {
            // text: 'Age',
          }
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function(val) {
              return val;
            }
          },
          y: {
            formatter: function(val) {
              return Math.abs(val) + "%";
            }
          }
        },
        title: {
          text: "A pirâmide etária de atendimento segundo AIH"
        },
        xaxis: {
          categories: [
            "< 1",
            "1-4",
            "5-9",
            "10-14",
            "15-19",
            "20-24",
            "25-29",
            "30-34",
            "35-39",
            "40-44",
            "45-49",
            "50-54",
            "55-59",
            "60-64",
            "65-69",
            "70-74",
            "75-79",
            "+80"
          ],
          title: {
            text: "Porcentagem %"
          },
          labels: {
            formatter: function(val) {
              return Math.abs(Math.round(val)) + "%";
            }
          }
        }
      },
      total: {
        error_code: 0,
        error_value: 0,
        cid: 0,
        cidDnc: 0
      },
      pagination: {
        error_code: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        },
        error_value: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        },
        cid: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        },
        cidDnc: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        }
      }
    };
  },
  async create() {},
  async mounted() {
    this.welcomeMessage();
    await this.getSerieRejectedCount();
    await this.getSerieRejectedSum();
    await this.getSerieCids();
    await this.getSerieCidsDnc();
    await this.getSerieSexFax();

    if (this.location_type != null) {
      this.getRegion();
    }
  },
  props: {},
  methods: {
    setFilters(params) {
      if (this.location_type == null) {
        params = this.setFiltersCids(params);
      } else {
        params.location_type = this.location_type;
        params.location_id = this.location_id;

        params = this.setFiltersCids(params);
      }
      return params;
    },
    setFiltersCids(params) {
      if (this.filter_cids != null) {
        params.column_filters = "diag_princ";
        params.term_filters = this.filter_cids;
      }

      if (this.filter_chapters_cids != null) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }

        for (const chapterCid of this.filter_chapters_cids) {
          params.column_filter_or.push("diag_princ");
          params.term_filter_or_range.push(chapterCid);
        }
      }

      if (this.filter_groups_cids != null) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }
        for (const groupCid of this.filter_groups_cids) {
          params.column_filter_or.push("diag_princ");
          params.term_filter_or_range.push(groupCid);
        }
      }

      return params;
    },
    async welcomeMessage(name = "") {
      await this.getDataSet();
      this.$emit("welcomeMessage", {
        title: `Dados de SIH do ano de ${this.dataSet.year} ${name}`
      });
    },
    async getDataSet() {
      let result = await Vue.$http.get(`v1/dataset/${this.id}/`);
      this.dataSet = result.data;
    },
    async getRegion() {
      let result;
      if (this.location_type == "city") {
        result = await Vue.$http.get(`v1/city_show/${this.location_id}/`);
      } else if (this.location_type == "microregion") {
        result = await Vue.$http.get(
          `v1/microregion_sesapi_show/${this.location_id}/`
        );
      } else if (this.location_type == "mesoregion") {
        result = await Vue.$http.get(
          `v1/mesoregion_sesapi_show/${this.location_id}/`
        );
      }
      this.region = result.data;
      this.welcomeMessage(this.region.name);
    },
    async getSerieRejectedCount() {
      let result;

      let params = {
        per: "co_erro",
        rating: "n_aih",
        operation: "count",
        per_page: this.pagination.error_code.perPage,
        page: this.pagination.error_code.currentPage
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];

      while (this.optionsBarRejectedCount.xaxis.categories.length > 0) {
        this.optionsBarRejectedCount.xaxis.categories.pop();
      }

      while (this.seriesBarRejectedCount.length > 0) {
        this.seriesBarRejectedCount.pop();
      }

      result.data.data.forEach(async itemData => {
        this.optionsBarRejectedCount.xaxis.categories.push(
          itemData.description
        );
        data.push(itemData.count);
      });

      this.seriesBarRejectedCount.push({
        name: "ErroCode",
        data: data
      });

      this.total.error_code = result.data.total;
    },
    async getSerieRejectedSum() {
      let result;

      let params = {
        per: "co_erro",
        rating: "val_tot",
        operation: "sum",
        per_page: this.pagination.error_value.perPage,
        page: this.pagination.error_value.currentPage
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];

      while (this.optionsBarRejectedValues.xaxis.categories.length > 0) {
        this.optionsBarRejectedValues.xaxis.categories.pop();
      }

      while (this.seriesBarRejectedValues.length > 0) {
        this.seriesBarRejectedValues.pop();
      }

      result.data.data.forEach(async itemData => {
        this.optionsBarRejectedValues.xaxis.categories.push(
          itemData.description
        );
        data.push(itemData.sum);
      });

      this.seriesBarRejectedValues.push({
        name: "ErroValue",
        data: data
      });

      this.total.error_value = result.data.total;
    },
    async getSerieCids() {
      let result;

      let params = {
        per: "diag_princ",
        rating: "n_aih",
        operation: "count",
        per_page: this.pagination.cid.perPage,
        page: this.pagination.cid.currentPage
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];

      while (this.optionsBarCid.xaxis.categories.length > 0) {
        this.optionsBarCid.xaxis.categories.pop();
      }

      while (this.seriesLinesCid.length > 0) {
        this.seriesLinesCid.pop();
      }

      while (this.seriesBarCid.length > 0) {
        this.seriesBarCid.pop();
      }
      /*
      this.optionsBarCid.xaxis.categories = [];
      this.seriesLinesCid = [];
      this.seriesBarCid = [];
      */
      result.data.data.forEach(async itemData => {
        this.cids.push(itemData.cid);
        this.getSerieCid(itemData.cid);
        this.optionsBarCid.xaxis.categories.push(itemData.description);
        data.push(itemData.count);
      });

      this.seriesBarCid.push({
        name: "quant",
        data: data
      });

      this.total.cid = result.data.total;
    },
    async getSerieCid(code) {
      let result;

      let params = {
        per: "mes_cmpt",
        rating: "diag_princ",
        operation: "count",
        column_filter: ["diag_princ"],
        term_filter: [code],
        per_page: 12,
        page: 1
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];
      result.data.data.forEach(async itemData => {
        data.push(itemData.count);
      });
      this.seriesLinesCid.push({
        name: code,
        data: data
      });
    },
    async getSerieCidsDnc() {
      let result;

      let params = {
        per: "diag_princ",
        rating: "n_aih",
        operation: "count",
        dnc: true,
        per_page: this.pagination.cidDnc.perPage,
        page: this.pagination.cidDnc.currentPage
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];

      while (this.optionsBarCidDnc.xaxis.categories.length > 0) {
        this.optionsBarCidDnc.xaxis.categories.pop();
      }

      while (this.seriesLinesCidDnc.length > 0) {
        this.seriesLinesCidDnc.pop();
      }

      while (this.seriesBarCidDnc.length > 0) {
        this.seriesBarCidDnc.pop();
      }
      /*
      this.optionsBarCidDnc.xaxis.categories = [];
      this.seriesLinesCidDnc = [];
      this.seriesBarCidDnc = [];
      */
      result.data.data.forEach(async itemData => {
        this.cidsDnc.push(itemData.cid);
        this.getSerieCidDnc(itemData.cid);
        this.optionsBarCidDnc.xaxis.categories.push(itemData.description);
        data.push(itemData.count);
      });

      this.seriesBarCidDnc.push({
        name: "quant",
        data: data
      });

      this.total.cidDnc = result.data.total;
    },
    async getSerieCidDnc(code) {
      let result;

      let params = {
        per: "mes_cmpt",
        rating: "diag_princ",
        operation: "count",
        column_filter: ["diag_princ"],
        term_filter: [code],
        per_page: 12,
        page: 1
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];
      result.data.data.forEach(async itemData => {
        data.push(itemData.count);
      });
      this.seriesLinesCidDnc.push({
        name: code,
        data: data
      });
    },
    async getSerieSexFax() {
      let direction = {
        left: -1,
        right: 1
      };

      let sex = [
        {
          name: "Masculino",
          value: 1,
          direction: direction.left,
          sum: 0,
          data: [],
          percent: []
        },
        {
          name: "Feminino",
          value: 3,
          direction: direction.right,
          sum: 0,
          data: [],
          percent: []
        }
      ];
      let result;
      let total = 0;

      let params = {
        per: "idade",
        rating: "n_aih",
        ranger: "faixa_etaria1",
        per_page: 10,
        page: 1
      };
      params = this.setFilters(params);

      for (var i = 0; i < sex.length; i++) {
        params.column_filter = ["sexo"];
        params.term_filter = [sex[i].value];

        result = await Vue.$http.get(
          `v1/dataset/consume/serie/range/datasus/sih/${this.id}/`,
          {
            params: params
          }
        );
        let data = await result.data.data;

        for (let prop in data[0]) {
          await sex[i].data.push(data[0][prop]);
          sex[i].sum += await data[0][prop];
        }

        total += sex[i].sum;
      }

      let one_percent = total / 100;

      for (let i = 0; i < sex.length; i++) {
        for (let j = 0; j < sex[i].data.length; j++) {
          sex[i].percent.push(
            (sex[i].data[j] / one_percent).toFixed(2) * sex[i].direction
          );
        }
        this.seriesSex.push({
          name: sex[i].name,
          data: sex[i].percent
        });
      }
    },
    setPageErrorCode(page) {
      this.pagination.error_code.currentPage = page;
      this.getSerieRejectedCount();
    },
    setPageErrorValue(page) {
      this.pagination.error_value.currentPage = page;
      this.getSerieRejectedSum();
    },
    setPageCid(page) {
      this.pagination.cid.currentPage = page;
      this.getSerieCids();
    },
    setPageCidDnc(page) {
      this.pagination.cidDnc.currentPage = page;
      this.getSerieCidsDnc();
    }
  }
};
</script>
